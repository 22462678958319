import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { CtaBanner, PreviewListing } from "@global";
import DailyHero from "@shared/dailyHero";
import FeaturedRecipe from "@shared/featuredRecipe";
import { useHasMounted } from "@util/hooks";
import ImageCtas from "@shared/imageCtas";
import InitialLoad from "@shared/initialLoad";

interface Props extends PageProps {
  data: Query;
}

export default function PageTemplate({ data }: Props) {
  const hasMounted = useHasMounted();
  const {
    allSanityBurgerThursdays: { nodes },
    allSanityRecipes: { nodes: recipes },
  } = data;
  if (nodes[0] == null) return null;
  const {
    seo,
    dailyHeroes,
    bannerCTAText,
    featuredRecipes,
    featuredTitle,
    baseColour,
    typeformFormId,
    recipePreview,
    bottomCtas,
  } = nodes[0];

  return (
    <div>
      <SEO seoData={seo} />
      {hasMounted ? (
        <>
          <DailyHero data={dailyHeroes} />
          <CtaBanner data={bannerCTAText} />
          <FeaturedRecipe
            data={featuredRecipes}
            featuredTitle={featuredTitle}
            baseColour={baseColour}
            formId={typeformFormId}
          />
          <PreviewListing data={recipePreview} recipes={recipes} baseColour={baseColour} />
          <ImageCtas data={bottomCtas} />
        </>
      ) : (
        <InitialLoad />
      )}
    </div>
  );
}

export const query = graphql`
  query pageQuery($slug: String) {
    allSanityBurgerThursdays(filter: { urlSlug: { eq: $slug } }) {
      nodes {
        seo {
          ...sanitySeo
        }
        dailyHeroes {
          ...sanityDailyHeroes
        }
        featuredTitle
        typeformFormId
        featuredRecipes {
          ...sanityRecipes
        }
        bannerCTAText {
          _rawColumnContent
        }
        baseColour
        recipePreview {
          ...sanityRecipePreview
        }
        bottomCtas {
          ...sanitySocialLinks
        }
      }
    }
    allSanityRecipes(
      filter: { categories: { elemMatch: { slug: { current: { eq: "burger" } } } } }
      sort: { order: DESC, fields: _createdAt }
    ) {
      nodes {
        ...sanityRecipes
      }
    }
  }
`;
