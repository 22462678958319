import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Controller, Navigation } from "swiper";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/pagination";

import { Maybe, SanitySocialLinks } from "@graphql-types";
import { Container } from "@util/standard";
import { TABLET_BREAKPOINT, colors } from "@util/constants";
import { Image } from "@global";
import { useCheckScreenWidth } from "@util/hooks";

const ImageContainer = styled(Container)`
  height: 400px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  &:hover {
    opacity: 0.5;
  }

  img {
    height: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 300px;
  }
`;

const StyledSwiper = styled(Swiper)`
  .swiper-pagination {
    bottom: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: ${colors.white};
  }
  .swiper-pagination-bullet-active,
  .swiper-pagination-bullet {
    background-color: ${colors.white};
  }
`;

const Wrapper = styled.div`
  margin-top: 50px;
  position: relative;
`;

interface Props {
  data: Maybe<SanitySocialLinks> | undefined;
}

export default function ImageCtas({ data }: Props) {
  if (data == null) return null;
  const { socialLinks } = data;
  if (socialLinks == null) return null;

  const { isMobileWidth } = useCheckScreenWidth();

  return (
    <Wrapper>
      <StyledSwiper
        modules={[Controller, Pagination, Navigation]}
        slidesPerView={isMobileWidth ? 1 : 3}
        spaceBetween={30}
        navigation={true}
        pagination={{
          clickable: true,
        }}
      >
        {socialLinks.map((item, index) => {
          if (item == null) return null;
          const { linkTo, socialIcon } = item;
          if (socialIcon == null) return;

          return (
            <SwiperSlide key={index}>
              <a target="_blank" href={linkTo as string}>
                <ImageContainer>
                  <Image data={socialIcon} objectFit="cover" />
                </ImageContainer>
              </a>
            </SwiperSlide>
          );
        })}
      </StyledSwiper>
    </Wrapper>
  );
}
