import React from "react";
import styled from "styled-components";
import moment from "moment";

import { Maybe, SanityRecipes, SanityColour } from "@graphql-types";
import { BlocksContent, BurgerBadge, Button, Image, TypeForm } from "@global";
import {
  TABLET_BREAKPOINT,
  SMALL_LAPTOP_BREAKPOINT,
  colorsRGB,
  RECIPES_SITE,
} from "@util/constants";
import { AbsoluteWrapper, Container, Separator } from "@util/standard";

const Wrapper = styled(Container)`
  margin: 20px 0 0 0;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const FeaturedCard = styled.div`
  display: flex !important;
  min-height: 550px;
  height: auto;
  background-color: ${colorsRGB.tan()};
  border-radius: 20px;
  overflow: hidden;

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const Title = styled(Container)<{ colorValue: Maybe<string> | undefined }>`
  justify-content: center;
  position: relative;
  width: fit-content;
  margin: 0 auto 20px auto;
  transform: rotate(355deg);

  ${({ colorValue }) => colorValue && `color: ${colorValue}; div { border-color: ${colorValue} }`}
`;

const ImageContainer = styled(Container)`
  min-width: 65%;
  width: 100%;
  position: relative;
  min-height: 45%;
`;

interface Props {
  data: Maybe<Maybe<SanityRecipes>[]> | undefined;
  featuredTitle: Maybe<string> | undefined;
  baseColour: Maybe<string> | undefined;
  formId: Maybe<string> | undefined;
}

export default function FeaturedRecipe({ data, featuredTitle, baseColour, formId }: Props) {
  if (data == null) return null;
  const dayOfMonth = moment().date();
  const dayOfWeek = moment().day();
  const recipe = data[dayOfMonth] ?? data[dayOfWeek];

  if (recipe == null) return null;
  const { title, description, descriptionFormatted, featureImages, slug } = recipe;
  const image = featureImages && featureImages[0];
  const color = baseColour;

  const FeaturedTitle = () => (
    <Title colorValue={color}>
      <AbsoluteWrapper left="-80px">
        <Separator width="230px" />
        <Separator width="230px" />
      </AbsoluteWrapper>
      <h2>{featuredTitle}</h2>
      <AbsoluteWrapper right="-80px" bottom="0">
        <Separator width="230px" />
        <Separator width="230px" />
      </AbsoluteWrapper>
    </Title>
  );

  return (
    <Wrapper>
      <Container width="100%" flexDirection="column">
        <FeaturedTitle />
        <FeaturedCard>
          {image && (
            <ImageContainer>
              <Image data={image} objectFit="cover" />
              <BurgerBadge
                width="150px"
                mobileWidth="100px"
                positioning={{ left: "10px", bottom: "10px" }}
              />
            </ImageContainer>
          )}

          <Container height="auto" width="90%" margin="30px" flexDirection="column">
            <h4>{title}</h4>
            {descriptionFormatted ? (
              <BlocksContent data={descriptionFormatted} />
            ) : (
              <p>{description}</p>
            )}

            {slug?.current && (
              <a target="_blank" href={`${RECIPES_SITE}/recipes/${slug.current}`}>
                <Button text="View Recipe" color={color} margin="30px 0 0 0" />
              </a>
            )}
          </Container>
        </FeaturedCard>
      </Container>
      {formId && <TypeForm formId={formId} />}
    </Wrapper>
  );
}
