import React from "react";
import styled from "styled-components";
import moment from "moment";

import { BlocksContent, BurgerBadge, Image } from "@global";
import { AbsoluteWrapper, Container, FullHeightContainer } from "@util/standard";
import { assets, colors, RECIPES_SITE } from "@util/constants";
import { SanityDailyHeroes, Maybe, SanityHero } from "@graphql-types";
import { useCheckScreenWidth } from "@util/hooks";

const Wrapper = styled(FullHeightContainer)`
  border-radius: 0 0 20px 20px;
  a {
    color: ${colors.white};
  }
`;

const HeroContentContainer = styled(Container)`
  max-width: 50%;
  position: absolute;
  flex-direction: column;
  bottom: 10%;
  left: 10%;
  right: 0;
  z-index: 1;
  transform: rotate(355deg);
  white-space: normal;
  h1,
  p {
    color: ${colors.white};
  }
`;

const HeroContent = ({ heroes }: { heroes: Maybe<SanityHero>[] | undefined }) => {
  if (heroes == null) return null;
  const dayOfWeek = () => {
    const dayUntil = moment().day() - 4;
    if (dayUntil < 0) return 7 + dayUntil;

    return dayUntil;
  };
  const todaysHero = heroes[dayOfWeek()];

  if (todaysHero == null) return null;
  const { image, mobileImage, richText } = todaysHero;
  const { isMobileWidth } = useCheckScreenWidth();

  return (
    <>
      {image && (
        <Image
          isBackground
          data={isMobileWidth && mobileImage ? mobileImage : image}
          filter="brightness(0.8)"
        />
      )}
      <HeroContentContainer>
        <BlocksContent data={richText} />
      </HeroContentContainer>
    </>
  );
};

interface Props {
  data: Maybe<SanityDailyHeroes> | undefined;
}

const DailyHero = ({ data }: Props) => {
  if (data == null) return null;

  const { heroes } = data;

  return (
    <a href={RECIPES_SITE} target="_blank">
      <Wrapper height="80vh">
        {heroes && <HeroContent heroes={heroes} />}
        <BurgerBadge
          badgeColor="red"
          width="250px"
          mobileWidth="150px"
          positioning={{ left: "20px", top: "20px", zIndex: 0 }}
        />

        <AbsoluteWrapper zIndex={1} bottom="50px" right="50px">
          <Container>
            <p style={{ marginRight: "10px" }}>Powered by </p>
            <img alt="recipes.co.nz" src={assets.logo} />
          </Container>
        </AbsoluteWrapper>
      </Wrapper>
    </a>
  );
};

export default DailyHero;
