import { BurgerBadge } from "@global";
import { colors } from "@util/constants";
import { Container } from "@util/standard";
import React from "react";

export default function InitialLoad() {
  return (
    <Container position="relative" minHeight="80vh" alignItems="center" justifyContent="center">
      <BurgerBadge badgeColor="red" width="300px" positioning={{ left: "40%", top: "0" }} />
      <h1 style={{ color: colors.tomatoSauce }}>Burger Thursday</h1>
    </Container>
  );
}
